<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!--  -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <DxDataGrid
              id="gvRoles"
              ref="grid"
              v-bind="options"
              :data-source="rolesStore"
              @data-error-occurred="dataOccurred"
              @init-new-row="onInitNewRow"
            >
              <DxColumn data-field="name" caption="Название">
                <DxRequiredRule />
              </DxColumn>
              <DxColumn data-field="usersCount" caption="Количество пользователей" />
              <DxColumn data-field="reports" :visible="false" :set-cell-value="(r, v) => setCellValue(r, v, 'reports', 1)" />
              <DxColumn data-field="references" :visible="false" :set-cell-value="(r, v) => setCellValue(r, v, 'references', 2)" />
              <DxColumn data-field="data" :visible="false" :set-cell-value="(r, v) => setCellValue(r, v, 'data', 3)" />
              <DxColumn data-field="settings" :visible="false" :set-cell-value="(r, v) => setCellValue(r, v, 'settings', 4)" />
              <DxColumn data-field="export" :visible="false" :set-cell-value="(r, v) => setCellValue(r, v, 'export', 5)" />
              <DxColumn data-field="addEditWrit" :visible="false" :set-cell-value="(r, v) => setCellValue(r, v, 'addEditWrit', 6)" />
              <DxColumn data-field="addEditAgreement" :visible="false" :set-cell-value="(r, v) => setCellValue(r, v, 'addEditAgreement', 7)" />
              <DxColumn data-field="addEditInhabitant" :visible="false" :set-cell-value="(r, v) => setCellValue(r, v, 'addEditInhabitant', 8)" />
              <DxColumn data-field="addEditDebtorNotes" :visible="false" :set-cell-value="(r, v) => setCellValue(r, v, 'addEditDebtorNotes', 9)" />
              <DxColumn data-field="addEditDebtorWorkLogs" :visible="false" :set-cell-value="(r, v) => setCellValue(r, v, 'addEditDebtorWorkLogs', 10)" />

              <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup">
                <DxPopup :show-title="true" width="800" height="auto" title="Редактирование роли" />
                <DxForm>
                  <DxSimpleItem :col-count="1" :col-span="2" item-type="group">
                    <DxSimpleItem data-field="name" />
                    <DxGroupItem caption="Доступ к разделам">
                      <DxSimpleItem data-field="reports" :editor-options="{ text: 'Работа с отчетами' }" editor-type="dxCheckBox">
                        <DxLabel text="Работа с отчетами" :visible="false" />
                      </DxSimpleItem>
                      <DxSimpleItem data-field="references" :editor-options="{ text: 'Работа со справочниками' }" editor-type="dxCheckBox">
                        <DxLabel text="Работа со справочниками" :visible="false" />
                      </DxSimpleItem>
                      <DxSimpleItem data-field="data" :editor-options="{ text: 'Работа с данными' }" editor-type="dxCheckBox">
                        <DxLabel text="Работа с данными" :visible="false" />
                      </DxSimpleItem>
                      <DxSimpleItem data-field="settings" :editor-options="{ text: 'Работа с настройками' }" editor-type="dxCheckBox">
                        <DxLabel text="Работа с настройками" :visible="false" />
                      </DxSimpleItem>
                    </DxGroupItem>
                    <DxGroupItem caption="Доступ к функциям">
                      <DxSimpleItem data-field="export" :editor-options="{ text: 'Выгрузка данных в файл' }" editor-type="dxCheckBox">
                        <DxLabel text="Выгрузка данных в файл" :visible="false" />
                      </DxSimpleItem>
                      <DxSimpleItem data-field="addEditWrit" :editor-options="{ text: 'Добавление / редактирование исков' }" editor-type="dxCheckBox">
                        <DxLabel  :visible="false" />
                      </DxSimpleItem>
                      <DxSimpleItem data-field="addEditAgreement" :editor-options="{ text: 'Добавление / редактирование соглашений' }" editor-type="dxCheckBox">
                        <DxLabel  :visible="false" />
                      </DxSimpleItem>
                      <DxSimpleItem data-field="addEditInhabitant" :editor-options="{ text: 'Добавление / редактирование жителей' }" editor-type="dxCheckBox">
                        <DxLabel :visible="false" />
                      </DxSimpleItem>
                      <DxSimpleItem data-field="addEditDebtorNotes" :editor-options="{ text: 'Добавление / редактирование примечания к лицевому счету' }" editor-type="dxCheckBox">
                        <DxLabel :visible="false" />
                      </DxSimpleItem>
                      <DxSimpleItem data-field="addEditDebtorWorkLogs" :editor-options="{ text: 'Добавление / редактирование персональной работы' }" editor-type="dxCheckBox">
                        <DxLabel :visible="false" />
                      </DxSimpleItem>
                    </DxGroupItem>
                  </DxSimpleItem>
                </DxForm>
              </DxEditing>

              <DxFilterRow :visible="true" />
              <DxPaging :page-size="10" />
              <DxPager
                :visible="true"
                :allowed-page-sizes="[5, 10, 50]"
                :show-page-size-selector="true"
                :show-navigation-buttons="true"
                :show-info="true"
              />
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";
import { DxPopup, DxForm } from "devextreme-vue/data-grid";
import { DxSimpleItem, DxGroupItem, DxLabel } from "devextreme-vue/form";
export default {
  components: {
    Layout,
    PageHeader,
    DxPopup,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxLabel,
  },

  data() {
    return {
      title: "Роли",
      items: [
        {
          text: "Администрирование",
          href: "/",
        },
        {
          text: "Роли",
          active: true,
        },
      ],

      rights: [],
      options: settings,
      rolesStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/roles/roles`,
        insertUrl: `${process.env.VUE_APP_URL}/api/devextreme/roles/create`,
        updateUrl: `${process.env.VUE_APP_URL}/api/devextreme/roles/update`,
        deleteUrl: `${process.env.VUE_APP_URL}/api/devextreme/roles/delete`,
        onBeforeSend: (_method, ajaxOptions) => {
          console.log(ajaxOptions);
        },
      }),
    };
  },
  methods: {
    dataOccurred() {},
    onInitNewRow(e) {
      e.data.reports = false;
      e.data.references = false;
      e.data.data = false;
      e.data.settings = false;
      e.data.export = false;
      e.data.addEditWrit = false;
      e.data.addEditAgreement = false;
      e.data.addEditInhabitant = false;
      e.data.addEditDebtorNotes = false;
      e.data.addEditDebtorWorkLogs = false;
    },
    setCellValue(rowData, value, field, rightId) {
      if (value) {
        this.rights.push(rightId);
      } else {
        const index = this.rights.indexOf(rightId);
        if (index > -1) {
          this.rights.splice(index, 1); // 2nd parameter means remove one item only
        }
      }

      rowData[field] = value;
      rowData.rightIds = this.rights;
    },
  },
};
</script>

<style scoped>
.dx-form-group-with-caption > .dx-form-group-content {
  padding: 0;
}
.dx-layout-manager .dx-field-item:not(.dx-first-row) {
  padding-top: 0 !important;
}
</style>
